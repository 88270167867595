document.addEventListener('DOMContentLoaded', function() {
    console.log('loaded');
    // Tag filter scrolling functionality
    const initTagFilterScroll = () => {
        const scroller = document.querySelector('.projects-filters');
        const filterContainer = document.querySelector('.projects-filters');
        const scrollRightButton = document.querySelector('[data-scroll="right"]');
        const scrollLeftButton = document.querySelector('[data-scroll="left"]');
        
        if (!scroller || !filterContainer) return;
        
        // Set initial button states
        updateScrollButtonsState();
        
        // Handle right scroll button click
        if (scrollRightButton) {
            scrollRightButton.addEventListener('click', () => {
                const scrollAmount = scroller.clientWidth;
                scroller.scrollBy({ left: scrollAmount, behavior: 'smooth' });
                
                // Update buttons state after scrolling
                setTimeout(updateScrollButtonsState, 300);
            });
        }
        
        // Handle left scroll button click
        if (scrollLeftButton) {
            scrollLeftButton.addEventListener('click', () => {
                const scrollAmount = -scroller.clientWidth;
                scroller.scrollBy({ left: scrollAmount, behavior: 'smooth' });
                
                // Update buttons state after scrolling
                setTimeout(updateScrollButtonsState, 300);
            });
        }
        
        // Update scroll buttons state
        function updateScrollButtonsState() {
            const isAtEnd = scroller.scrollLeft + scroller.clientWidth >= filterContainer.scrollWidth - 5;
            const isAtStart = scroller.scrollLeft <= 5;
            
            if (scrollRightButton) {
                if (isAtEnd) {
                    scrollRightButton.setAttribute('disabled', 'disabled');
                    scrollRightButton.classList.add('disabled');
                } else {
                    scrollRightButton.removeAttribute('disabled');
                    scrollRightButton.classList.remove('disabled');
                }
            }
            
            if (scrollLeftButton) {
                if (isAtStart) {
                    scrollLeftButton.setAttribute('disabled', 'disabled');
                    scrollLeftButton.classList.add('disabled');
                } else {
                    scrollLeftButton.removeAttribute('disabled');
                    scrollLeftButton.classList.remove('disabled');
                }
            }
        }
        
        // Listen for scroll events to update buttons state
        scroller.addEventListener('scroll', updateScrollButtonsState);
        
        // Update on resize
        window.addEventListener('resize', updateScrollButtonsState);
    };
    
    // Initialize tag filtering functionality
    const initTagFiltering = () => {
        const filterButtons = document.querySelectorAll('.projects-filters [data-filter]');
        const projectCards = document.querySelectorAll('.project-card');
        let activeFilter = 'all';

        if (!filterButtons.length || !projectCards.length) return;

        // Add click event listeners to filter buttons
        filterButtons.forEach(button => {
            button.addEventListener('click', () => {
                const filter = button.getAttribute('data-filter');
                
                // Check if clicking the same filter that is already active
                if (activeFilter === filter && button.classList.contains('active')) {
                    // Deselect the current filter
                    button.classList.remove('active');
                    activeFilter = 'all';
                    // Show all projects
                    filterProjects('all');
                } else {
                    // Remove active class from all buttons and add to current
                    filterButtons.forEach(btn => btn.classList.remove('active'));
                    button.classList.add('active');
                    
                    // Store the active filter
                    activeFilter = filter;
                    
                    // Filter the projects
                    filterProjects(filter);
                }
            });
        });

        // Filter projects based on selected tag
        function filterProjects(filter) {
            projectCards.forEach(card => {
                if (filter === 'all') {
                    card.style.display = '';
                } else {
                    const tagClass = `tag-${filter}`;
                    if (card.classList.contains(tagClass)) {
                        card.style.display = '';
                    } else {
                        card.style.display = 'none';
                    }
                }
            });
        }

        // Set "all" as default active filter
        const allFilterButton = document.querySelector('.projects-filters [data-filter="all"]');
        if (allFilterButton) {
            allFilterButton.classList.add('active');
        }
    };
    
    initTagFilterScroll();
    initTagFiltering();
});
